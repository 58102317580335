import React from 'react'
import { Link } from "gatsby"
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faPlay } from '@fortawesome/free-solid-svg-icons'
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Quote from "../../components/quote"
import SingleContent from "../../components/single-column"
import ScheduleADemoButton from '../../components/schedule-a-demo-button'
import BadgeContent from "../../components/badge-component"
// import {Helmet} from 'react-helmet'

const supportDetails = {
  quotes: [
    {
      heading: 'No second class citizens with great support on every plan.',
      profile: require('../../images/profile-1.jpg'),
      quote: "Gemma was able to see my screen and show me exactly where I was going wrong - getting me quickly back on track!",
      parag: [
        {
          title: "Help directly from our team",
          text: "Start a live conversation within Billsby and our team will be in touch as soon as they can to get things solved."
        },
        {
          title: "Hop on a call and share your screen",
          text: "You can always book an appointment with one of our specialist support team members and we can even see your screen to help solve your problem."
        },
        {
          title: "Get help and help others in our community",
          text: "Whether your'e just getting started or a Billsby expert, our online community can answer your questions and enables you to help other customers too."
        }
      ]
    },
    {
      heading: "We store your ideas in feature requests, not black holes.",
      profile: require('../../images/profile-2.jpg'),
      quote: "It's great to get progress updates as my feature request works its way through the various teams at Billsby.",
      parag: [
        {
          title: "Always receptive to your ideas",
          text: "Always receptive to your ideas Our support team is always listening to customers ideas and suggestions, and we're always eager to take on new feature requests."
        },
        {
          title: "Every request is logged and categorized",
          text: " When you ask for something new, rest assured that your request is held in our systems, categorized and considered."
        },
        {
          title: "Keeping you up-to-date as we do things",
          text: " Because we store your ideas safely, we also keep track of who wanted what. So when your request is delivered, you'll be the first to know."
        }
      ]
    },
    {
      heading: "Empowered to say yes, and also to say no.",
      profile: require('../../images/profile-3.jpg'),
      quote: "The team at Billsby went way out of their way to help us get our system up and running in just a couple days. Top notch support.",
      parag: [
        {
          title: "When it matters, we'll try to make it happen",
          text: " From exposing new API endpoints in less than a day, to quickly fixing major bugs, we'll always work hard on what matters most."
        },
        {
          title: "Equipped with deep product knowledge",
          text: " Because our support team are Billsby experts, we can help you to deeply understand how things work and find great solutions."
        },
        {
          title: "Not here to sell",
          text: " If we're not a fit, our support team are allowed to recommend alternatives - even if it means we lose out on a sale."
        }
      ]
    },
  ]
}

const singleContent = {
  title: "Great support content, that we're proud to share.",
  text: "We're really proud of the documentation and support content we've put together to help you grow your subscription business, so we've not hidden it behind a login. Take a look, even before you join us.",
  button: [
    {
      buttonColor: "blue",
      url: "https://support.billsby.com",
      btnText: "Documentation",
    },
    {
      buttonColor: "blue",
      url: "https://support.billsby.com/reference",
      btnText: "API specification",
    }
  ]
}

const badgeData = {
  section: "home",
  link: true,
  FallBadges: [
    {
      img: require("../../images/best-support.svg"),
      altText: 'high-performance',
    },
    {
      img: require("../../images/easiest-to-do-bussiness-with-fall.svg"),
      altText: 'easiest-to-do-bussiness-with-fall',
    },
  ],
}

const Support = () => {
  return (
    <Layout>
      <SEO
        title="Obsessive Support| Billsby | Powerful, customizable subscription billing software"
        description="Great subscription billing and recurring payments software needs incredible help and support - our UK US based teams are here to help."
        url="https://www.billsby.com/product/support"
      />

      <div className="support-page">
        <div className="support-banner-with-badge">
          <div className="section-hero">
            <div className="container">
              <h1 className="section-heading">Some people think our support team are a little bit obsessed.</h1>
              <p className="section-text">We think they're brilliant, and so will you. At Billsby, <Link to="/pricing">we don't make money until you do</Link> so our UK and US based support teams goals are directly aligned with yours - to help you to build and grow your subscription business.</p>

              <div className="btn-wrapper">
                <a href="https://app.billsby.com/registration" rel="noopener noreferrer" target='_blank' className="btn-orange">Sign up today</a>
                <ScheduleADemoButton buttonColor="black" />
              </div>
            </div>
          </div>
          <BadgeContent content={badgeData} />
        </div>

        <Quote content={supportDetails} />

        <div className="get-started-holder">
          <div className="container container-flex">
            <div className="content-holder">
              <h2 className="section-heading">We're here to help when you're just getting started.</h2>
              <p className="section-text">Written and video guides help you to get started integrating Billsby with your website builder of choice, making it easy to start selling subscriptions even if you don't have a developer.</p>
              {/* <p className="section-text">It's so easy, a fourteen year old could do it. In fact, they did.</p>

                <button className="btn-white adoric_video_gettingstartedchallenge" id="adoric_video_gettingstartedchallenge"><FontAwesomeIcon className="icon-play" icon={ faPlay } />Billsby Getting Started Challenge (5:26)</button> */}
            </div>
            <div className="brand-holder">
              <div className="brand"></div>
              <div className="brand"></div>
              <div className="brand"></div>
            </div>
          </div>
        </div>

        <div className="support-holder">
          <div className="container">
            <div className="support-item subscription-holder">
              <div className="support-content">
                <h2 className="section-heading">Helping you to grow your subscription business.</h2>

                <div className="support-info">
                  <p className="section-title">Fully supported through your $5,000 free trial </p>
                  <p className="section-text">As you try Billsby for free, our team are available in exactly the same way as they will be once you're paying.</p>
                </div>
                <div className="support-info">
                  <p className="section-title">Keeping you in-the-loop</p>
                  <p className="section-text">Our industry experts write to our customers with news of payment regulation changes and subscription billing updates to help you stay compliant.</p>
                </div>
                <div className="support-info">
                  <p className="section-title">Your personal named account manager</p>
                  <p className="section-text">When you're on track to make $5 million of transactions per year through Billsby, we'll give you a named account manager to help and advocate for you.</p>
                </div>

                <div className="btn-wrapper">
                  <a href="https://app.billsby.com/registration" rel="noopener noreferrer" target='_blank' className="btn-orange">Sign up today</a>
                  <ScheduleADemoButton buttonColor="black" />
                </div>
              </div>

              <div className="support-profile">
                <img src={require('../../images/support-talk.svg')} alt="Helping you to grow your subscription business." />
              </div>
            </div>
          </div>
        </div>

        <SingleContent content={singleContent} />

        <div className="support-holder">
          <div className="container">
            <div className="support-item subscription-holder">
              <div className="support-content">
                <h2 className="section-heading">Instant support when you need it the most.</h2>

                <div className="support-info">
                  <p className="section-text">Finally, from within your account, you can always trigger a chat session with Billsbot (in fact, he'll check if he can help before passing you to the human team). Billsbot is trained in hundreds of the most common questions we hear from our customers, and can direct you to walkthroughs, resources and video tutorials too.</p>

                  <p className="section-text"> The result? Most simple queries are answered on the spot, with no need to wait for a member of the team, or comb through our documentation.</p>
                </div>
              </div>

              <div className="support-profile">
                <img alt="Instant support when you need it the most" src={require('../../images/robo.svg')} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Support
